/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { forwardRef } from "react";
import Button from "../components/Button";
import EvilKermitHero from "../assets/images/KermitHero.png";
import EvilKermitBackground from "../assets/images/backgroundimage.png";
import Cloud from "../assets/images/Frame 34463.png";
import "../App.css";
import Presale from "../components/Presale";

const EvilKermitLanding = forwardRef<HTMLDivElement>((_, ref) => {
  return (
    <div ref={ref}>
      <div className="relative grid grid-rows-[auto_1fr] ">
        {/* Navigation */}
        <div className="col-start-1 row-start-1 ">
          <img
            className="w-full h-[100%] object-cover object-bottom"
            src={EvilKermitBackground}
            alt="Hero"
          />
        </div>
        <div className="col-start-1 row-start-1 flex  flex-col items-center">
          {/* Main Content */}
          {/* Title Section */}
          <div className="text-center mt-16">
            <div
              className="text-[140px] font-bold text-green-500 logofont"
              style={{
                filter: "drop-shadow(0 0 10px rgba(0,255,0,0.3))",
              }}
            >
              EVILKERMIT
            </div>

            <div className="text-xl font-[600] text-gray-200 mt-[-35px] leading-[24px] inter-uniquifier">
              Embrace Your Inner Evil, Invest in EvilKermit.
            </div>
          </div>
          {/* Buttons Section */}
          <div className="flex gap-6 mb-16 mt-10 ">
            <Presale />
          </div>
          {/* Hero Image Section */}
          <div className="flex">
            <img
              src={EvilKermitHero}
              alt="Evil Kermit Character"
              className="w-full mt-auto object-cover"
            />
          </div>
        </div>
        <div className="col-start-1 row-start-1 flex pointer-events-none">
          <img className="w-full mt-auto" src={Cloud} alt="Cloud" />
        </div>
      </div>
      {/* Bottom Content */}
      <div className="flex flex-col gap-[20px] px-[200px] text-center pb-[80px] ">
        <h2 className="text-[75px] font-normal ">
          <span className="text-red-600 titlered">WHY</span>{" "}
          <span className="text-green-500 titlegreen">EVILKERMIT?</span>
        </h2>

        <p className="text-[#C5DED1] text-[16px] leading-[24px] font-normal inter-uniquifier">
          EvilKermit isn't your typical token—it's a community-driven project
          with a twist! Embrace the power of a token that rewards holders and
          keeps things interesting with a built-in burn mechanism. Every
          transaction fuels the fire, with a 1% burn per transaction, decreasing
          supply over time and increasing value for those who stay loyal.
        </p>

        <p className="text-[#C5DED1] text-[16px] leading-[24px] font-normal inter-uniquifier">
          Whether you're here for the memes or the long game, EvilKermit is
          designed to be fun, engaging, and rewarding. Join a community of
          like-minded enthusiasts ready to embrace their inner 'evil' and make a
          mark in the token space!
        </p>
      </div>
    </div>
  );
});

export default EvilKermitLanding;
