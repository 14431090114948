import "../App.css";

interface CountdownTimerProps {
  days: String;
  hours: String;
  minutes: String;
  seconds: String;
}

const CountdownTimer: React.FC<CountdownTimerProps> = ({
  days,
  hours,
  minutes,
  seconds,
}) => {
  return (
    <>
      <div className="flex justify-center  gap-[11px]">
        <div className="flex flex-col justify-center gap-[10px]">
          <div className="logofont timerback">
            <span className="text-[60px] leading-[80px] ">{days}</span>
          </div>
          <span className="inter-uniquifier text-[16px] font-normal leading-[24px] text-[#C5DED1] text-center">
            DAYS
          </span>
        </div>

        <div className="text-[40px] mt-[13px]">:</div>

        <div className="flex flex-col justify-center gap-[10px]">
          <div className="logofont timerback">
            <span className="text-[60px] leading-[80px] ">{hours}</span>
          </div>
          <span className="inter-uniquifier text-[16px] font-normal leading-[24px] text-[#C5DED1] text-center">
            HOURS
          </span>
        </div>
        <div className="text-[40px] mt-[13px]">:</div>

        <div className="flex flex-col justify-center gap-[10px]">
          <div className="logofont timerback">
            <span className="text-[60px] leading-[80px] ">{minutes}</span>
          </div>
          <span className="inter-uniquifier text-[16px] font-normal leading-[24px] text-[#C5DED1] text-center">
            MINUTES
          </span>
        </div>
        <div className="text-[40px] mt-[13px]">:</div>

        <div className="flex flex-col justify-center gap-[10px]">
          <div className="logofont timerback">
            <span className="text-[60px] leading-[80px] ">{seconds}</span>
          </div>
          <span className="inter-uniquifier text-[16px] font-normal leading-[24px] text-[#C5DED1] text-center">
            SECONDS
          </span>
        </div>
      </div>
    </>
  );
};

export default CountdownTimer;
