import React, { forwardRef } from "react";
import "../App.css";
import Telegram from "../assets/images/Telegram.png";
import Discode from "../assets/images/Discode.png";
import Twitter from "../assets/images/Twitter.png";

const Community = forwardRef<HTMLDivElement>((_, ref) => {
  return (
    <div
      ref={ref}
      className="flex flex-col gap-[50px] items-center justify-center pt-[80px] px-[200px]"
    >
      <div className="text-[75px] leading-[75px] font-normal titlegreen">
        <span className="titlered">COMMUNITY</span> & CONTACT US
      </div>
      <div className="flex gap-[100px]">
        <div className="flex flex-col justify-between max-w-[580px]">
          <div className="flex flex-col gap-[30px]">
            <div className="flex flex-col gap-[10px]">
              <div className="font-bold text-[24px] leading-[36px] inter-uniquifier">
                Contact US
              </div>
              <div className="font-normal text-[16px] leading-[24px] inter-uniquifier text-[#C5DED1]">
                Stay updated and join the conversation!
                <br />
                Follow us on our official channels:
              </div>
            </div>
            <div className="flex gap-[20px]">
              <a
                href="https://t.me/EvilKermit"
                target="_blank"
                rel="noreferrer"
              >
                <img src={Telegram} alt="" />
              </a>
              <a href="@Discode" target="_blank" rel="noreferrer">
                <img src={Discode} alt="" />
              </a>
              <a
                href="https://www.x.com/EvilKermit"
                target="_blank"
                rel="noreferrer"
              >
                <img src={Twitter} alt="" />
              </a>
            </div>
          </div>
          <div className="flex flex-col gap-[30px] ">
            <div className="flex flex-col gap-[10px]">
              <div className="font-bold text-[24px] leading-[36px] inter-uniquifier">
                Newsletter Signup
              </div>
              <div className="font-normal text-[16px] leading-[24px] text-[#C5DED1]">
                Never miss an update! Sign up for our newsletter to receive the
                latest news on EvilKermit, including roadmap milestones, presale
                alerts, and community events. Enter your email to join the
                EvilKermit community!
              </div>
            </div>
            <div className="flex flex-col gap-[20px]">
              <input
                type="text"
                className="bg-[#007D3A1A] border border-[#155E0F] rounded-[10px] px-[20px] py-[10px] placeholder-[#C5DED1] placeholder:text-[16px]"
                placeholder="Enter your email address"
              />
              <button className=" px-[20px] py-[10px] button-background font-[700] text-[18px] leading-[27px] text-[#0F0F0F]">
                Subscribe
              </button>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-[50px] p-[50px] contact-form border rounded-[50px]  border-[#056B03] ">
          <form className="flex flex-col justify-between gap-[29px] ">
            <div className="text-center flex flex-col gap-[10px]">
              <div className="text-[32px] leading-[32px] font-[700] inter-uniquifier">
                Contact Form
              </div>
              <div className="text-[16px] leading-[24px] font-[400] inter-uniquifier">
                Have questions or need support? Reach out to us directly! Fill
                out the contact form below, and we’ll get back to you as soon as
                possible.
              </div>
            </div>
            <div className="flex flex-col gap-[10px] ">
              <input
                type="text"
                className="bg-[#007D3A1A] border border-[#155E0F] rounded-[10px] px-[20px] py-[10px] placeholder-[#C5DED1] placeholder:text-[16px]"
                placeholder="Enter your email address"
              />
              <input
                type="email"
                className="bg-[#007D3A1A] border border-[#155E0F] rounded-[10px] px-[20px] py-[10px] placeholder-[#C5DED1] placeholder:text-[16px]"
                placeholder="Enter your email address"
              />
              <textarea
                className="bg-[#007D3A1A] border border-[#155E0F] rounded-[10px] px-[20px] py-[10px] placeholder-[#C5DED1] placeholder:text-[16px] break-words"
                placeholder="Enter your message (e.g., inquiries, support requests, or feedback)"
              ></textarea>
            </div>
            <div>
              <button className="button-background px-[20px] py-[10px] w-full font-[700] text-[18px] leading-[27px] text-[#0F0F0F]">
                Send Message
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
});

export default Community;
