import WalletButton from "../components/WalletConnectButton";
import React from "react";
import logo from "../assets/images/logo.png";

interface NavbarProps {
  section1Ref: React.RefObject<HTMLDivElement>;
  section2Ref: React.RefObject<HTMLDivElement>;
  section3Ref: React.RefObject<HTMLDivElement>;
  section4Ref: React.RefObject<HTMLDivElement>;
  section5Ref: React.RefObject<HTMLDivElement>;
}

const Navbar: React.FC<NavbarProps> = ({
  section1Ref,
  section2Ref,
  section3Ref,
  section4Ref,
  section5Ref,
}) => {
  const scrollToSection = (ref: React.RefObject<HTMLDivElement>) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <nav className="fixed top-0 left-0 w-full z-10 flex justify-between items-center px-8 py-4 bg-black/50 backdrop-blur-md">
      <div className="flex items-center gap-3">
        <img src={logo} alt="Evil Kermit Logo" className="rounded-full" />
        <span className="text-white font-normal text-[30px] leading-[24px] logofont ">
          EVILKERMIT
        </span>
      </div>

      <div className="flex items-center gap-[40px] leading-[22px] text-[18px] inter-uniquifier">
        <div
          className="text-white hover:text-green-400 focus:text-green-500 cursor-pointer"
          onClick={() => scrollToSection(section1Ref)}
        >
          Home
        </div>
        <div
          className="text-white hover:text-green-400 focus:text-green-500 cursor-pointer"
          onClick={() => scrollToSection(section2Ref)}
        >
          Tokenomics
        </div>
        <div
          className="text-white hover:text-green-400 focus:text-green-500 cursor-pointer"
          onClick={() => scrollToSection(section3Ref)}
        >
          Roadmap
        </div>
        <div
          className="text-white hover:text-green-400 focus:text-green-500 cursor-pointer"
          onClick={() => scrollToSection(section4Ref)}
        >
          Pre-Sale
        </div>
        <div
          className="text-white hover:text-green-400 focus:text-green-500 cursor-pointer"
          onClick={() => scrollToSection(section5Ref)}
        >
          Contact Us
        </div>
        {/* <Button variant="connect">CONNECT WALLET</Button> */}
        <WalletButton />
      </div>
    </nav>
  );
};

export default Navbar;
