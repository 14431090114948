import React, { useState, useEffect } from "react";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import ETH from "../assets/images/image 276.png";
import EVLK from "../assets/images/presale-mark.png";
import USDT from "../assets/images/USDT.png";
import abi from "../config/abis/abi.json";
import erc20abi from "../config/abis/erc20abi.json";
import CountdownTimer from "../components/Countdown";
import { PresaleCA, usdtAddress } from "../config/params/contractAddresses";
import {
  useWriteContract,
  useReadContract,
  useReadContracts,
  useAccount,
  useBalance,
  usePublicClient,
} from "wagmi";
import {
  encodeFunctionData,
  formatEther,
  parseEther,
  parseUnits,
  formatUnits,
} from "viem";
import formatterDecimal from "../utils/formatters/formatterDecimal";
import Notification from "../components/notification/Notification";
import { toast, Bounce } from "react-toastify";
import { getGasPrice } from "@wagmi/core";
import { config } from "../config/wagmiConfig";
import WidgetProgressBar from "./WidgetProgressBar";

const gas = await getGasPrice(config);

const Presale = () => {
  // const { isConnected, address } = useAccount();
  const { address, isConnected } = useAccount();
  let tx;

  // const evilTokenAddress = "0xA186Df4C1783907Dc9D64EDF9DF8B84766F5aD2f";
  const [buyMethod, setBuyMethod] = useState<String>("ETH");
  const [currPhasePrice, setCurrPhasePrice] = useState<bigint>(0n);
  const [currentStage, setCurrentStage] = useState<bigint>(0n);
  const [valueEthForBuying, setValueEthForBuying] = useState<any>("0");
  const [valueUsdtForBuying, setValueUsdtForBuying] = useState<any>("0");
  const [endTime, setEndTime] = useState<number>(0);
  const [usdtAllowance, setUsdtAllowance] = useState<any>(0);
  const [inputStatus, setInputStatus] = useState(true);
  const [receiveTokenWithETH, setReceivedTokenWithETH] = useState<bigint>(0n);
  const [receiveTokenWithUSDT, setReceivedTokenWithUSDT] = useState<bigint>(0n);
  const [remainTime, setRemainTime] = useState({
    dd: "00",
    hh: "00",
    mm: "00",
    ss: "00",
  });
  const [gasPrice, setGasPrice] = useState<String>("0");
  const [balanceOfEth, setBalanceOfEth] = useState<bigint>(0n);
  const [balanceOfUsdt, setBalanceOfUsdt] = useState<bigint>(0n);
  const [tokenBought, setTokenBought] = useState<bigint>(0n);
  const [raisedTotalUsdt, setRaisedTotalUsdt] = useState<bigint>(0n);
  const [soldTotalToken, setSoldTotalToken] = useState<bigint>(0n);

  // buy token notification
  const [isActiveNotification, setIsActiveNotification] = useState(false);
  const [notificationDone, setNotificationDone] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState<String>("");

  const balanceOfEthData = useBalance({
    address: address,
  });

  const { data: balanceOfUsdtData } = useReadContract({
    address: usdtAddress,
    abi: erc20abi,
    functionName: "balanceOf",
    args: [address],
  });
  const handleInputForBuying = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (buyMethod === "ETH") setValueEthForBuying(e.target.value);
    if (buyMethod === "USDT") setValueUsdtForBuying(e.target.value);
    setInputStatus(true);
  };

  const PresaleContract = {
    address: PresaleCA,
    abi: abi,
  } as const;
  const publicClient = usePublicClient();
  const { data, refetch: readRefetch } = useReadContracts({
    contracts: [
      {
        ...PresaleContract,
        functionName: "getCurrentPhasePrice",
      },
      {
        ...PresaleContract,
        functionName: "endTime",
      },
      {
        ...PresaleContract,
        functionName: "activePhase",
      },
      {
        ...PresaleContract,
        functionName: "estimateTokenForEth",
        args: [parseEther(valueEthForBuying)],
      },
      {
        ...PresaleContract,
        functionName: "estimateTokenForUsdt",
        args: [parseUnits(valueUsdtForBuying, 6)],
      },
      {
        ...PresaleContract,
        functionName: "token",
      },
      {
        ...PresaleContract,
        functionName: "buyers",
        args: [address],
      },
      {
        ...PresaleContract,
        functionName: "totalUSDTRaised",
      },
      {
        ...PresaleContract,
        functionName: "totalTokensSold",
      },
    ],
  });

  const {
    // data: buyTokenWithEthData,
    writeContractAsync: buyTokenWithEthWrite,
    isPending: buyTokenWithEthIsLoading,
    isSuccess: buyTokenWithEthIsSuccess,
    error: buyTokenWithETHError,
  } = useWriteContract();

  const {
    // data: approveUSDTData,
    writeContractAsync: approveUSDTWrite,
    isPending: approveUSDTIsLoading,
    isSuccess: approveUSDTIsSuccess,
    error: approveUSDTError,
  } = useWriteContract();

  const {
    // data: buyTokenWithUsdtData,
    writeContractAsync: buyTokenWithUsdtWrite,
    isPending: buyTokenWithUsdtIsLoading,
    isSuccess: buyTokenWithUsdtIsSuccess,
    error: buyTokenWithUsdtError,
  } = useWriteContract();

  const {
    // data: claimTokenData,
    writeContractAsync: claimTokenWrite,
    isPending: claimTokenIsLoading,
    isSuccess: claimTokenIsSuccess,
    error: claimTokenError,
  } = useWriteContract();

  const getEstimateGas = async () => {
    const data = encodeFunctionData({
      ...PresaleContract,
      functionName: "buyWithETH",
      // value: [parseEther(valueEthForBuying.toString())],
    });
    let value =
      buyMethod === "ETH"
        ? Number(valueEthForBuying)
        : Number(valueUsdtForBuying);

    if (value === 0 || value === undefined || value === null) {
      setGasPrice("0");
    } else {
      try {
        const estimatedGas = await publicClient!.estimateGas({
          data,
          account: address,
          to: PresaleCA,
          value:
            buyMethod === "ETH"
              ? parseEther(String(value ? value : 0.00000001))
              : parseUnits(String(value ? value : 0.00000001), 6),
        });
        const gasPrice = await publicClient!.getGasPrice();
        setGasPrice(formatEther(estimatedGas * gasPrice));
      } catch (error) {
        console.log(error);
        setGasPrice("Transaction exceeds the balance of the account.");
      }
    }
  };

  const formatedCurrentTokenPrice = (data?.[0].result as bigint) ?? 0n;
  const formatedEndTime = (data?.[1].result as bigint) ?? 0n;
  const currentPhase = (data?.[2].result as bigint) ?? 0n;
  const formatedEstimatedTokenForEth = (data?.[3].result as bigint) ?? 0n;
  const formatedEstimatedTokenForUsdt = (data?.[4].result as bigint) ?? 0n;
  // const tokenAddress = data?.[5].result ?? "";
  const buyersData = (data?.[6].result as any) ?? {};
  const buyerTokenBought = (buyersData?.[2] as bigint) ?? 0n;
  const totalUsdRaised = (data?.[7].result as bigint) ?? 0n;
  const totalTokenSold = (data?.[8].result as bigint) ?? 0n;

  const handleBuyWithEth = async () => {
    setBuyMethod("ETH");
  };

  const handleBuyWithUsdt = async () => {
    setBuyMethod("USDT");
    if (isConnected) {
    } else {
    }
  };

  // claim token
  const handleClaimToken = async () => {
    if (buyerTokenBought > 0 && Date.now() >= endTime) {
      toast("🦄 Wow so easy!", {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });

      claimTokenWrite({
        ...PresaleContract,
        functionName: "claimToken",
      });
      await readRefetch();
    } else {
      console.log("Token will be claimed after presale ends.");
    }
  };

  //handling message
  const buyTokenLoadingMsg = (textMsg: String) => {
    setIsActiveNotification(true);
    setNotificationMsg(textMsg);
    setTimeout(() => {
      setNotificationDone(false);
    }, 5000);
  };

  const buyTokenSuccessMsg = () => {
    setNotificationDone(true);
    setNotificationMsg("Your transaction has been successfully completed");
    setTimeout(() => {
      setNotificationDone(false);
    }, 5000);
  };

  const connectWalletMsg = () => {
    setIsActiveNotification(true);
    setNotificationMsg("You have to connect wallet for buying Tokens");
    setTimeout(() => {
      setIsActiveNotification(false);
    }, 5000);
  };

  const errorMsg = (errorMsg: String) => {
    setIsActiveNotification(true);
    setNotificationMsg(errorMsg);
    setTimeout(() => {
      setIsActiveNotification(false);
    }, 5000);
  };

  useEffect(() => {
    if (address) {
      getEstimateGas();
      console.log("TransactionFee::", gasPrice);
    }
  }, [address, valueEthForBuying, valueUsdtForBuying]);
  useEffect(() => {
    const targetTimestamp = 1735621200000; // Your target timestamp

    const formatTimer = () => {
      const currentTimestamp = Date.now();
      const difference = targetTimestamp - currentTimestamp;

      if (difference > 0) {
        const timeLeft = {
          dd: String(Math.floor(difference / (1000 * 60 * 60 * 24))).padStart(
            2,
            "0"
          ),
          hh: String(Math.floor((difference / (1000 * 60 * 60)) % 24)).padStart(
            2,
            "0"
          ),
          mm: String(Math.floor((difference / (1000 * 60)) % 60)).padStart(
            2,
            "0"
          ),
          ss: String(Math.floor((difference / 1000) % 60)).padStart(2, "0"),
        };
        return timeLeft;
      } else {
        // Countdown has finished
        return { dd: "00", hh: "00", mm: "00", ss: "00" };
      }
    };

    const updateTimer = () => {
      setRemainTime(formatTimer());
    };

    updateTimer(); // Initial call to set the timer immediately

    const timerId = setInterval(updateTimer, 1000); // Update every second

    return () => clearInterval(timerId); // Cleanup on unmount
  }, []);
  useEffect(() => {
    if (
      buyTokenWithEthIsLoading ||
      claimTokenIsLoading ||
      buyTokenWithUsdtIsLoading ||
      approveUSDTIsLoading
    ) {
      buyTokenLoadingMsg("Transaction Processing. Click “Confirm”.");
    }

    if (
      buyTokenWithEthIsSuccess ||
      buyTokenWithUsdtIsSuccess ||
      claimTokenIsSuccess ||
      approveUSDTIsSuccess
    ) {
      buyTokenSuccessMsg();
    }

    if (buyTokenWithETHError) {
      setIsActiveNotification(false);
      const errorMessage = buyTokenWithETHError.message || "An error occurred.";
      // Find the index of the first period
      const firstPeriodIndex = errorMessage.indexOf(".");
      // Extract the substring until the first period
      const resultMessage =
        firstPeriodIndex !== -1
          ? errorMessage.substring(0, firstPeriodIndex)
          : errorMessage;
      console.log("buyTokenWithETH Error Message", resultMessage); // Log the message until the first period
      errorMsg(resultMessage);
    }

    if (claimTokenError) {
      setIsActiveNotification(false);
      const errorMessage = claimTokenError.message || "An error occurred.";
      // Find the index of the first period
      const firstPeriodIndex = errorMessage.indexOf(".");
      // Extract the substring until the first period
      const resultMessage =
        firstPeriodIndex !== -1
          ? errorMessage.substring(0, firstPeriodIndex)
          : errorMessage;
      console.log("claimToken Error Message", resultMessage); // Log the message until the first period
      errorMsg(resultMessage);
    }

    if (buyTokenWithUsdtError) {
      setIsActiveNotification(false);
      const errorMessage =
        buyTokenWithUsdtError.message || "An error occurred.";
      // Find the index of the first period
      const firstPeriodIndex = errorMessage.indexOf(".");
      // Extract the substring until the first period
      const resultMessage =
        firstPeriodIndex !== -1
          ? errorMessage.substring(0, firstPeriodIndex)
          : errorMessage;
      console.log("buyTokenWithUSDT Error Message", resultMessage); // Log the message until the first period
      errorMsg(resultMessage);
    }

    if (approveUSDTError) {
      setIsActiveNotification(false);
      const errorMessage = approveUSDTError.message || "An error occurred.";
      // Find the index of the first period
      const firstPeriodIndex = errorMessage.indexOf(".");
      // Extract the substring until the first period
      const resultMessage =
        firstPeriodIndex !== -1
          ? errorMessage.substring(0, firstPeriodIndex)
          : errorMessage;
      console.log("approveUSDT Error Message", resultMessage); // Log the message until the first period
      errorMsg(resultMessage);
    }
  }, [
    buyTokenWithETHError,
    claimTokenError,
    buyTokenWithUsdtError,
    approveUSDTError,
    buyTokenWithEthIsSuccess,
    buyTokenWithUsdtIsSuccess,
    claimTokenIsSuccess,
    approveUSDTIsSuccess,
  ]);

  useEffect(() => {
    if (!isConnected) {
      connectWalletMsg();
    }
    if (formatedCurrentTokenPrice) {
      setCurrPhasePrice(formatedCurrentTokenPrice);
    }
    if (currentPhase) {
      setCurrentStage(currentPhase);
    }
    if (formatedEndTime) {
      setEndTime(Number(formatedEndTime));
    }

    if (balanceOfEthData.data?.value) {
      setBalanceOfEth(balanceOfEthData.data?.value);
    }

    if (balanceOfUsdtData) {
      setBalanceOfUsdt(balanceOfUsdtData as bigint);
    }
    if (buyerTokenBought) {
      setTokenBought(buyerTokenBought);
    }
    if (totalUsdRaised) {
      setRaisedTotalUsdt(totalUsdRaised);
    }
    if (totalTokenSold) {
      setSoldTotalToken(totalTokenSold);
    }
  }, [
    isActiveNotification,
    notificationDone,
    notificationMsg,
    isConnected,
    buyerTokenBought,
    totalUsdRaised,
    totalTokenSold,
    balanceOfEthData,
    balanceOfUsdtData,
    receiveTokenWithETH,
    receiveTokenWithUSDT,
  ]);
  const handleBuy = async () => {
    if (isConnected) {
      if (buyMethod === "ETH") {
        if (valueEthForBuying !== "") {
          try {
            tx = await buyTokenWithEthWrite({
              ...PresaleContract,
              functionName: "buyWithETH",
              value: parseEther(valueEthForBuying.toString()),
            });
            setValueEthForBuying("0");
            setReceivedTokenWithETH(0n);
            await readRefetch();
          } catch (error) {
            console.error(
              "Error during transaction or function execution:",
              error
            );
          }
        } else {
          console.log("Enter ether amount for buying tokens");
          setInputStatus(false);
        }
      }
      if (buyMethod === "USDT") {
        let hash;
        if (valueUsdtForBuying !== "") {
          try {
            let allowance = (await publicClient?.readContract({
              address: usdtAddress,
              abi: erc20abi,
              functionName: "allowance",
              args: [address, PresaleCA],
            })) as bigint;
            console.log("allowance", allowance);
            if (allowance < parseUnits(valueUsdtForBuying, 6)) {
              hash = await approveUSDTWrite({
                address: usdtAddress,
                abi: erc20abi,
                functionName: "approve",
                args: [PresaleCA, parseUnits(valueUsdtForBuying, 6)],
              });

              console.log("approve step 1", allowance);
              // biome-ignore lint/style/noNonNullAssertion: <explanation>
              await publicClient?.waitForTransactionReceipt({ hash: hash! });
            }

            console.log("approve step 2");
            tx = await buyTokenWithUsdtWrite({
              ...PresaleContract,
              functionName: "buyWithUSDT",
              args: [address, parseUnits(valueUsdtForBuying.toString(), 6)],
            });
            setValueUsdtForBuying("0");
            setReceivedTokenWithUSDT(0n);
            await readRefetch();
            console.log("transation", tx);
          } catch (error) {
            console.log(
              "error for buying with usdt>>>\n",
              buyTokenWithUsdtError?.cause
            );
          }
        } else {
          console.log("Enter ether amount for buying tokens");
          setInputStatus(false);
        }
      }
    } else {
      connectWalletMsg();
    }
  };

  const onFillMax = () => {
    if (isConnected) {
      if (buyMethod === "ETH") {
        setValueEthForBuying(
          formatterDecimal(
            formatEther(balanceOfEth ? (balanceOfEth as bigint) : 0n),
            4
          )
        );
      }
      if (buyMethod === "USDT")
        setValueUsdtForBuying(
          formatterDecimal(String(formatUnits(balanceOfUsdt, 6)), 4)
        );
    } else {
      connectWalletMsg();
    }
  };

  return (
    <div>
      {isActiveNotification && (
        <Notification
          notificationDone={notificationDone}
          textMessage={notificationMsg}
        />
      )}
      <div className="px-[20px] py-[10px] transition-transform inter-uniquifier text-[19px] bg-green-500 hover:bg-green-600 text-black shadow-[0_0_20px_rgba(0,255,0,0.3)] button-background font-semibold w-full text-center">
        Token Sale Open - Buy TOKEN Now
      </div>
      <div className=" flex flex-col  rounded-b-[30px] px-[26px] pt-[36px] pb-[14px] contact-form border border-[#056b03] default_cursor_cs">
        <CountdownTimer
          days={remainTime.dd}
          hours={remainTime.hh}
          minutes={remainTime.mm}
          seconds={remainTime.ss}
        />
        <div className="text-center">
          <div className=" font-bold text-[25px] mt-[20px]">
            Until token sale ends
          </div>
          <div className="inter-uniquifier leading-[50px] tracking-[2px]">
            {" "}
            USDT Raised:{` ${raisedTotalUsdt}/$3000000`}
          </div>
          <div className="inter-uniquifier leading-[10px]">
            {" "}
            Your Purchased TOKEN = {formatEther(soldTotalToken)}
          </div>
        </div>
        <div className="flex  text-[#7E8FAA] text-xs items-center  bg-none mt-[40px] gap-1">
          <div className="h-px flex flex-1 bg-[#315737]"></div>
          <div className="text-[18px]">1 TOKEN = $0.0005</div>
          <div className="h-px flex flex-1 bg-[#315737]"></div>
        </div>
        <div className="py-6 flex justify-between gap-3">
          <button
            className={`px-[40px] py-[14.5px] rounded-[10px]  border border-green-600  duration-300  w-full ${
              buyMethod === "ETH"
                ? "bg-[#3fce3f] text-black"
                : "bg-black text-[#0EDB0E]"
            }`}
            onClick={() => handleBuyWithEth()}
          >
            <div className="flex items-center justify-center text-center">
              <img src={ETH} alt="ETH" className="w-[34px] h-[34px] mr-2" />
              <span className="text-[17px] inter-uniquifier font-semibold ">
                ETH
              </span>
            </div>
          </button>
          <button
            className={`px-[40px] py-[14.5px] rounded-[10px]  border border-green-600  duration-300  w-full ${
              buyMethod === "ETH"
                ? "bg-black text-[#0EDB0E]"
                : "bg-[#3fce3f] text-black"
            }`}
            onClick={() => handleBuyWithUsdt()}
          >
            <div className="flex items-center justify-center text-center">
              <img src={USDT} alt="USDT" className="w-[32px] h-[32px] mr-2" />
              <span className="text-[17px] inter-uniquifier font-semibold ">
                USDT
              </span>
            </div>
          </button>
        </div>
        <div className="flex  text-[#7E8FAA] text-xs items-center  bg-none mt-[20px] gap-1">
          <div className="h-px flex flex-1 bg-[#315737]"></div>
          <div className="text-[18px]">
            {buyMethod === "ETH"
              ? ` ETH Balance : ${formatterDecimal(
                  formatEther(balanceOfEth),
                  4
                )} ETH`
              : ` USDT Balance : $${formatUnits(balanceOfUsdt, 6)} `}
          </div>
          <div className="h-px flex flex-1 bg-[#315737]"></div>
        </div>
        <div>
          <div className="flex justify-between gap-[10px] py-8">
            <div className="text-[23px] inter-uniquifier">
              <div className="flex justify-between text-xl">
                <label>
                  {buyMethod === "ETH" ? "Pay with ETH" : "Pay with USDT"}
                </label>
                <label
                  className="text-[#209482] hover:cursor-pointer"
                  onClick={onFillMax}
                >
                  {"MAX"}
                </label>
              </div>
              <div>
                <div className="relative inline-block">
                  <input
                    className={`w-full h-[60px] bg-black outline-none border ${
                      inputStatus ? "border-[#056b03]" : "border-[#ff0000]"
                    } rounded-[5px] p-[5px] pr-12`}
                    type="number"
                    step="1"
                    value={
                      buyMethod === "ETH"
                        ? valueEthForBuying
                        : valueUsdtForBuying
                    }
                    onChange={handleInputForBuying}
                    placeholder="Enter amount"
                  />
                  <img
                    className="absolute right-3 top-[50%] translate-y-[-50%] w-8 h-8"
                    src={buyMethod === "ETH" ? ETH : USDT}
                    alt="ETH icon"
                  />
                </div>
              </div>
            </div>
            <div className="text-[23px] inter-uniquifier">
              <div className="flex justify-between text-xl">
                <label>{"Receive TOKEN"}</label>
              </div>
              <div>
                <div className=" relative inline-block">
                  <input
                    className={`w-full h-[60px] bg-black outline-none border 
                   border-[#056b03]
                     rounded-[5px] p-[5px] pr-12`}
                    type="number"
                    step="1"
                    value={
                      buyMethod === "ETH"
                        ? formatterDecimal(
                            formatEther(formatedEstimatedTokenForEth),
                            8
                          )
                        : formatterDecimal(
                            formatEther(formatedEstimatedTokenForUsdt),
                            8
                          )
                    }
                    onChange={handleInputForBuying}
                    placeholder="Enter amount"
                  />
                  <img
                    className="absolute right-3 top-[50%] translate-y-[-50%] w-8 h-8"
                    src={EVLK}
                    alt="USDT icon"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex  text-[#7E8FAA] text-xs items-center  bg-none">
          <div className="h-px flex flex-1 bg-[#315737]"></div>
        </div>

        <div>
          <div className="w-[600px] px-10 pt-10 pb-5 text-center">
            {`${formatterDecimal(
              String(gasPrice),
              7
            )} ETH is reserved for gas fees. The actual amount used will depend on the noetwork activity at the time`}
          </div>
          <WidgetProgressBar currentValue={100000000} maxValue={1000000000} />
          {/* <WidgetProgressBar currentValue={Number(formatEther(totalTokenSold))} maxValue={1000000000} /> */}
          <div className="flex gap-[20px] py-4">
            <ConnectButton.Custom>
              {({
                account,
                chain,
                openAccountModal,
                openChainModal,
                openConnectModal,
                authenticationStatus,
                mounted,
              }) => {
                // Note: If your app doesn't use authentication, you
                // can remove all 'authenticationStatus' checks
                const ready = mounted && authenticationStatus !== "loading";
                const connected =
                  ready &&
                  account &&
                  chain &&
                  (!authenticationStatus ||
                    authenticationStatus === "authenticated");
                return (
                  <div
                    className="w-full"
                    {...(!ready && {
                      "aria-hidden": true,
                      style: {
                        opacity: 0,
                        pointerEvents: "none",
                        userSelect: "none",
                      },
                    })}
                  >
                    {(() => {
                      if (!connected) {
                        return (
                          <button
                            className="bg-black px-[40px] py-[18.5px] rounded-[10px]  border border-green-600 hover:bg-[#3fce3f] duration-300 text-[#0EDB0E] hover:text-[#000000] w-full"
                            onClick={openConnectModal}
                          >
                            <div className="flex items-center justify-center text-center">
                              <img
                                src={EVLK}
                                alt="USDT"
                                className="w-[32px] h-[32px] mr-2"
                              />
                              <span className="text-[17px] inter-uniquifier font-semibold ">
                                Connect Wallet
                              </span>
                            </div>
                          </button>
                        );
                      }
                      if (chain.unsupported) {
                        return (
                          <button onClick={openChainModal} type="button">
                            Wrong network
                          </button>
                        );
                      }
                      return (
                        <button
                          className="bg-black px-[40px] py-[18.5px] rounded-[10px]  border border-green-600 hover:bg-[#3fce3f] duration-300 text-[#0EDB0E] hover:text-[#000000] w-full"
                          onClick={handleBuy}
                        >
                          <div className="flex items-center justify-center text-center">
                            <img
                              src={EVLK}
                              alt="USDT"
                              className="w-[32px] h-[32px] mr-2"
                            />
                            <span className="text-[17px] inter-uniquifier font-semibold ">
                              Buy TOKENS
                            </span>
                          </div>
                        </button>
                      );
                    })()}
                  </div>
                );
              }}
            </ConnectButton.Custom>
          </div>
          {1735621200000 - Date.now() < 0 && (
            <div className="mt-3">
              <button
                className="bg-black px-[40px] py-[18.5px] rounded-[10px]  border border-green-600 hover:bg-[#3fce3f] duration-300 text-[#0EDB0E] hover:text-[#000000] w-full"
                onClick={() => handleClaimToken()}
              >
                <div className="flex items-center justify-center text-center">
                  <img
                    src={EVLK}
                    alt="USDT"
                    className="w-[32px] h-[32px] mr-2"
                  />
                  <span className="text-[17px] inter-uniquifier font-semibold ">
                    Claim Tokens
                  </span>
                </div>
              </button>
            </div>
          )}
        </div>
        <div className="text-[15px] font-semibold mt-[20px]  inter-uniquifier flex gap-[6px] justify-center">
          <span className=" opacity-60">Embrace Your Inner Evil</span>{" "}
          <span className="text-[#0EDB0E]">Invest In EvilKermit.</span>
        </div>
      </div>
    </div>
  );
};

export default Presale;
