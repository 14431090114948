/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Footimage from "../assets/images/Footimage.png";
import Footlogo from "../assets/images/Foot-logo.png";
import Telegram from "../assets/images/Telegram-mini.png";
import Discode from "../assets/images/Discode-mini.png";
import Twitter from "../assets/images/Twitter-mini.png";
import "../App.css";

const FootPage: React.FC = () => {
  return (
    <div>
      <div className="flex justify-center ml-[194px] mr-[-80px] mt-[35px] z-[-1]">
        <img src={Footimage} alt="Footimage"></img>
      </div>
      <div className="absolute bottom-0 left-0 w-full  ">
        <div className="flex flex-col items-center justify-center backdrop-blur-xl footerbackground border-t border-t-[#C5DED140]">
          <div className="flex mt-[20px] mb-[40px] mx-[200px] gap-[50px] ">
            <div className="flex flex-col gap-[10px] w-[342px]">
              <div className="flex items-center gap-[10px] text-[30px] leading-[24px]">
                <img src={Footlogo} alt="logo"></img>
                <div className="font-[400] text-[30px] leading-[24px] logofont">
                  EvilKermit
                </div>
              </div>
              <div className="leading-[36px] text-[24px] inter-uniquifier font-bold">
                Embrace Your Inner Evil
              </div>
              <div className="text-[14px] leading-[17px] font-bold inter-uniquifier">
                Disclaimer
              </div>
              <div
                className="text-[12px] leading-[14px] font-[400] inter-uniquifier text-[#C5DED1]"
                style={{ fontStyle: "italic" }}
              >
                EvilKermit is a community-driven, meme-inspired project and
                should be considered a high-risk investment. Always do your own
                research.
              </div>
            </div>
            <div className="flex flex-col gap-[20px] inter-uniquifier">
              <div className="text-[14px] leading-[17px] font-bold">
                Quick Links
              </div>
              <div className="flex flex-col gap-[12px]">
                <div className="text-[14px] font-[400] leading-[17px] text-[#C5DED1]">
                  Home
                </div>
                <div className="text-[14px] font-[400] leading-[17px] text-[#C5DED1]">
                  Tokenomics
                </div>
                <div className="text-[14px] font-[400] leading-[17px] text-[#C5DED1]">
                  Roadmap
                </div>
                <div className="text-[14px] font-[400] leading-[17px] text-[#C5DED1]">
                  Pre-Sale
                </div>
                <div className="text-[14px] font-[400] leading-[17px] text-[#C5DED1]">
                  Contact Us
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-[20px] inter-uniquifier">
              <div className="text-[14px] leading-[17px] font-bold">
                Community
              </div>
              <div className="flex gap-[10px] items-center">
                <div>
                  <img src={Telegram} alt="logo"></img>
                </div>
                <a
                  href="https://t.me/EvilKermit"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="flex text-[14px] leading-[17px] font-[400] text-[#C5DED1]">
                    @EvirkermitTG
                  </div>
                </a>
              </div>
              <div className="flex gap-[10px] items-center">
                <div>
                  <img src={Discode} alt="logo"></img>
                </div>
                <a href="#">
                  <div className="flex text-[14px] leading-[17px] font-[400] text-[#C5DED1]">
                    @EvirkermitDiscord
                  </div>
                </a>
              </div>
              <div className="flex gap-[10px] items-center">
                <div>
                  <img src={Twitter} alt="logo"></img>
                </div>
                <a
                  href="https://www.x.com/EvilKermit"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="flex text-[14px] leading-[17px] font-[400] text-[#C5DED1]">
                    @Evirkermitofficial
                  </div>
                </a>
              </div>
            </div>
            <div className="flex flex-col gap-[20px] inter-uniquifier">
              <div className="text-[14px] leading-[17px] font-bold">
                Contact Us
              </div>
              <div className="flex flex-col gap-[12px]">
                <div className="text-[14px] font-[400] max-w-[268px] leading-[21px] text-[#C5DED1]">
                  For inquiries or support, please reach out via our Contact
                  Form or email us at{" "}
                  <span className="text-[#2EA92E]">
                    <a href="support@evilKermit.com">support@evilKermit.com</a>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center text-[14px] inter-uniquifier font-bold leading-4 mb-[20px] mx-[200px]">
            © 2024 EvilKermit. All rights reserved.
          </div>
        </div>
      </div>
    </div>
  );
};

export default FootPage;
