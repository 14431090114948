import React from 'react';
import '../App.css';
interface ConnectWalletProps {
  walletIcon: string;
  walletTitle: string;
  walletText: string;
  onConnect: () => void;
}

const ConnectWallet: React.FC<ConnectWalletProps> = ({ walletIcon,walletTitle, walletText, onConnect }) => {
  return (
    <div className="flex flex-col gap-[30px] p-[20px] items-center">
        <div className="wallet-icon" onClick={onConnect}>
            <img src={walletIcon} alt="Wallet Icon" />
        </div>
        <div className="flex flex-col gap-[10px] font-[400] text-[16px] leading-[24px] text-[#C5DED1] text-center inter-uniquifier">
            <div className='text-[18px] font-[700] text-[#ffffff] leading-[18px]'>{walletTitle}</div>
            <div>{walletText}</div>
        </div> 
        </div>
  );
};

export default ConnectWallet;
