import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { useAccount } from "wagmi";

import React, { useRef } from "react";

import "./App.css";
import Header from "./page/header";
import Tokenomics from "./page/ChartPart";
import RoadMap from "./page/RoadMap";
import PresaleCountDown from "./page/Presale";
import EvilKermitBuy from "./page/HowToBuy";
import Community from "./page/Community";
import FootPage from "./page/Footpage";
import Navbar from "./page/Navbar";
import ThemeStyles from "./assets/styles/ThemeStyles";
import { ThemeProvider } from "styled-components";

import "@rainbow-me/rainbowkit/styles.css";
import "./App.css";

function App() {
  const section1Ref = useRef<HTMLDivElement | null>(null);
  const section2Ref = useRef<HTMLDivElement | null>(null);
  const section3Ref = useRef<HTMLDivElement | null>(null);
  const section4Ref = useRef<HTMLDivElement | null>(null);
  const section5Ref = useRef<HTMLDivElement | null>(null);

  return (
    <ThemeProvider theme={ThemeStyles}>
      <div className="w-full body-background text-white relative overflow-hidden flex flex-col itemss-center">
        <Navbar
          section1Ref={section1Ref}
          section2Ref={section2Ref}
          section3Ref={section3Ref}
          section4Ref={section4Ref}
          section5Ref={section5Ref}
        />
        <Header ref={section1Ref} />
        <Tokenomics ref={section2Ref} />
        <RoadMap ref={section3Ref} />
        <PresaleCountDown ref={section4Ref} />
        <EvilKermitBuy />
        <Community ref={section5Ref} />
        <FootPage />
      </div>
    </ThemeProvider>
  );
}

export default App;
