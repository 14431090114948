import React, { useEffect } from "react";
import NotificationStyleWrapper from "./Notification.style";
import ProcessingIcon from "../../assets/images/processing.png";
import SuccessfulIcon from "../../assets/images/successful.svg";

interface NotificationProps {
  notificationDone: boolean;
  textMessage: String;
}

const Notification: React.FC<NotificationProps> = ({
  notificationDone,
  textMessage,
}) => {
  return (
    <NotificationStyleWrapper>
      {notificationDone ? (
        <div className="gittu-toast done">
          <div className="gittu-toast-inner">
            <div className="gittu-toast__content">
              <img
                className="icon-successful"
                src={SuccessfulIcon}
                alt="icon"
              />
              <p>Awesome ! Your transaction has been successfully complete</p>
            </div>
          </div>
        </div>
      ) : (
        <div className="gittu-toast">
          <div className="gittu-toast-inner">
            <div className="gittu-toast__content">
              <img className="icon-spin" src={ProcessingIcon} alt="icon" />
              <p>{textMessage}</p>
            </div>
          </div>
        </div>
      )}
    </NotificationStyleWrapper>
  );
};

export default Notification;
