import React from "react";
import "../App.css";
import ConnectWallet from "../components/ConnectWallet";
import Metamask from "../assets/images/Metamask.png";
import SelectEvil from "../assets/images/SelectEvil.png";
import Confirm from "../assets/images/confirm.png";
import ArrowDown from "../assets/images/bxs_down-arrow.png";

const EvilKermitBuy: React.FC = () => {
  return (
    <div className="flex flex-col gap-[50px] justify-center py-[80px] px-[200px]">
      <div className="flex gap-[20px] text-[75px] justify-center">
        <span className="titlered">HOW TO </span>
        <span className="titlegreen">BUY</span>
      </div>
      <div className="flex gap-[40px] items-center">
        <ConnectWallet
          walletIcon={Metamask}
          walletTitle={"1.Connect Your Wallet"}
          walletText={
            'Click "Connect Wallet" and select MetaMask or another compatible wallet.'
          }
          onConnect={() => {}}
        />
        <div>
          <img src={ArrowDown} alt="Arrowup" className="w-[55px] h-[24px]" />
        </div>
        <ConnectWallet
          walletIcon={SelectEvil}
          walletTitle={"1.Connect Your Wallet"}
          walletText={
            'Click "Connect Wallet" and select MetaMask or another compatible wallet.'
          }
          onConnect={() => {}}
        />
        <div>
          <img src={ArrowDown} alt="Arrowup" className="w-[55px] h-[24px]" />
        </div>
        <ConnectWallet
          walletIcon={Confirm}
          walletTitle={"1.Connect Your Wallet"}
          walletText={
            'Click "Connect Wallet" and select MetaMask or another compatible wallet.'
          }
          onConnect={() => {}}
        />
      </div>
    </div>
  );
};

export default EvilKermitBuy;
